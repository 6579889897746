import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import Header from './Header';
import ProductInvoiceTable from './ProductInvoiceTable';

export default function Invoice() {


    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);
    const [data5, setData5] = useState([]);
    const [data6, setData6] = useState([]);
    const [data7, setData7] = useState([]);
    const [data8, setData8] = useState([]);
    const columns = [
        {
            title: 'Item', field: 'product_name', width: '100px'
        },
        {
            title: 'Price', field: 'unit_price',

        },
        {
            title: 'Qt', field: 'quantity',

        },
        {
            title: 'Sub', field: 'price',
        },
    ]

    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-latest-sale')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData(resp))

    }, [])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-total-price')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData2(resp))

    }, [])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-total-paid')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData3(resp))

    }, [])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-total-due')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData4(resp))

    }, [])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-subtotal')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData5(resp))

    }, [])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-discount')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData6(resp))

    }, [])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-received')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData7(resp))

    }, [])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-change')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData8(resp))

    }, [])

    const api = axios.create({
        baseURL: `https://newpos.madadsdigital.com/backend/public/`
    })

    const handleRowDelete = (oldData, resolve) => {
        api.delete("api/delete-product/" + oldData.id)
            .then(res => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                resolve()
            })
            .catch(error => {
                setErrorMessages(["Delete failed! Server error"])
                setIserror(true)
                resolve()
            })
    }

    let today = new Date();
    let date = today.getDate() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getFullYear();


    var hours = today.getHours();
    var minutes = today.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;


    const user = JSON.parse(localStorage.getItem('userData'));

    const { id: id, name: name, email: email, password: password, account_type: account_type } = user;


    return (
        <div>
            {/* /.card-header */}
            <div className="invoice p-3 mb-3" id="invoice" style={{ width: '340px', height: 'auto', fontSize: '12px', overflowX: 'hidden' }}>
                {/* info row */}
                <div className="row invoice-info">
                    <div className="col-12" style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                        <img src="../dist/img/logo.jpg" style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }} />
                    </div>
                    <div className='col-12'>
                        <address  >
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', fontSize: '16px' }}>
                                08 Noyasarak, Sylhet 3100<br />
                                Phone: +8801681230000<br />
                                www.femmesylhet.com
                            </div>
                        </address>
                    </div>
                    {/* /.col */}
                    <div className="col-6 " style={{ fontSize: '14px' }}>
                        <b>Date: {date}
                        </b>

                        <br />
                    </div>
                    <div className="col-6 " style={{ fontSize: '14px', textAlign: 'right' }}>
                        <b>Time: {strTime}
                        </b>

                        <br />
                    </div>
                    <div className='col-12 ' style={{ textAlign: 'center', fontSize: '14px' }}>
                        <b>Invoice:
                            {
                                data.map((row, i) => {
                                    if (i == 0) {
                                        return (
                                            <b> {row.invoice_number} </b>
                                        )
                                    }
                                })
                            }

                        </b>
                    </div>
                    <div className="col-6 " style={{ fontSize: '14px' }}>
                        <b>Name:
                            {
                                data.map((row, i) => {
                                    if (i == 0) {
                                        return (
                                            <b> {row.customer_name} </b>
                                        )
                                    }
                                })
                            }
                        </b>
                    </div>
                    <div className="col-6 " style={{ fontSize: '14px', textAlign: 'right' }}>

                        {
                            data.map((row, i) => {
                                if (i == 0) {
                                    return (
                                        <address>Phone: {row.customer_number}</address>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
                <ProductInvoiceTable/>
                {/* /.row */}
                {/* Table row */}
                {/* <MaterialTable
                    data={data}
                    columns={columns}
                    title='Shopping Cart'
                    options={{
                        pageSizeOptions: [5, 10],
                        pageSize: 20,
                        addRowPosition: 'first',
                        actionsColumnIndex: 0,
                        search: false,
                        showTitle: false,
                        paging: false,
                        toolbar: false,
                        rowStyle: {
                            fontSize: 12,
                        },
                        tableLayout: 'fixed',
                    }}

                // editable={{
                //     onRowDelete: (oldData) =>
                //         new Promise((resolve) => {
                //             handleRowDelete(oldData, resolve)
                //         }),
                // }}
                /> */}
                {/* /.row */}
                <div className="row">
                    {/* accepted payments column */}
                    {/* /.col */}
                    <div className="col-12" style={{ fontSize: '16px' }}>
                        <div className="table-responsive" style={{ textAlign: 'right' }}>
                            <table style={{ float: 'right', marginTop: '20px', marginRight: '50px' }} >
                                <tbody>
                                    <tr>
                                        <th style={{ width: '80%', textAlign: 'right' }}>Subtotal: </th>
                                        <td>
                                            {data5}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{ width: '80%', textAlign: 'right' }}>Discount: </th>
                                        <td>
                                            {data6}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{ width: '80%', textAlign: 'right' }}><strong style={{ fontSize: '14px' }}>Grand Total: </strong></th>
                                        <td>
                                            <strong style={{ fontSize: '14px' }}>{data2}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Paid:</th>
                                        <td>{data3}</td>
                                    </tr>
                                    <tr>
                                        <th>Due:</th>
                                        <td>{data4}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Received:</th>
                                        <td>{data7}</td>
                                    </tr>
                                    <tr>
                                        <th>Change Given:</th>
                                        <td>{data8}</td>
                                    </tr>
                                </tbody></table>
                        </div>
                    </div>
                    <div className='col-sm-12' style={{ marginBottom: '14px' }}>
                        <strong >
                            <br></br>
                            <br></br>
                            Signature: {name}
                        </strong>
                    </div>
                    <div className='col-sm-12'>
                        <strong >
                            Service Provider:
                            <br></br>
                            <br></br>
                        </strong>
                    </div>
                    <div className='col-sm-12' style={{ marginTop: '25px' }}>
                        <p className="text-muted well well-sm shadow-none" style={{ textAlign: 'center' }}>
                            <i className="fas fa-code" /> Developed By: Mad Tech Digital
                        </p>
                    </div>
                    {/* /.col */}
                </div>
                {/* /.row */}
                {/* this row will not appear when printing */}

            </div>


        </div>
    )
}

