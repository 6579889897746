import React, { Component } from "react";
import axios from "axios";
import { useState } from "react";
import { Redirect } from "react-router";

export default class Register extends Component {


    state = {
        name: '',
        email: '',
        password: '',
        account_type: ''
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    registerSubmit = async (e) => {
        e.preventDefault();
        const res = await axios.post('https://newpos.madadsdigital.com/backend/public/api/register', this.state);

        if (res.data.status = "200") {
            this.setState({
                name: '',
                email: '',
                password: '',
                account_type: ''

            })
            alert("Successfully Added");
            <Redirect to="/" />

        }
        else {
            alert("Failed")
        }
    }

    render() {
        return (
            <div>
                <body className="hold-transition register-page">
                    <div className="register-box">
                        <div className="card card-outline card-primary">
                            <div className="card-header text-center">
                                <a href="../../index2.html" className="h1"><b>Femme</b></a>
                            </div>
                            <div className="card-body">
                                <p className="login-box-msg">Register a new account</p>
                                <form onSubmit={this.registerSubmit}>
                                    <div className="input-group mb-3">
                                        <select className="form-control select2" id="account_type" name="account_type" onChange={this.handleInput} value={this.state.account_type}>
                                            <option value>Select account type</option>
                                            <option value="admin">Admin</option>
                                            <option value="seller">Seller</option>
                                        </select>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" onChange={this.handleInput} name="name" value={this.state.name} placeholder="Full name" />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="email" className="form-control" onChange={this.handleInput} name="email" value={this.state.email} placeholder="Email" />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-envelope" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="password" className="form-control" onChange={this.handleInput} name="password" value={this.state.password} placeholder="Password" />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* /.col */}
                                        <div className="col-4">
                                            <button type="submit" className="btn btn-primary btn-block">Register</button>
                                        </div>
                                        {/* /.col */}
                                    </div>
                                </form>
                            </div>
                            {/* /.form-box */}
                        </div>{/* /.card */}
                    </div>
                </body>
            </div>
        );
    }
}