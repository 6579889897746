import React from "react";
import logo from './logo.svg';
import './App.css';
import Dashboard from './Dashboard';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, BrowserRouter } from "react-router-dom";
import AddProduct from "./AddProduct";
import Header from "./Header";
import Footer from "./Footer";
import Menu from "./Menu";
import AddCompany from "./AddCompany";
import ViewCompany from "./ViewCompany";
import Table from "./Table2";
import AddCategory from "./AddCategory";
import ViewCategory from "./ViewCategory";
import ViewProduct from "./ViewProduct";
import AddService from "./AddService";
import ViewService from "./ViewService";
import Register from "./auth/Register";
import AddMember from "./AddMember";
import AdminDashboard from "./AdminDashboard";
import axios from "axios";
import Login from "./auth/Login";
import Bill from "./Bill";

axios.defaults.baseURL = "https://newpos.madadsdigital.com/backend/public/";

export default function App() {
  return (
    <>
<BrowserRouter forceRefresh={true}>
      <Router>
        <Switch>
          <Route path="/register" component={withRouter(Register)}>
            <Register />
          </Route>
          <Route path="/add-product" component={withRouter(AddProduct)}>
            <AddProduct />
          </Route>
          <Route path="/add-product" component={withRouter(AddProduct)}>
            <AddProduct />
          </Route>
          <Route path="/admin" component={withRouter(AdminDashboard)}>
            <AdminDashboard />
          </Route>
          <Route path="/view-product" component={withRouter(ViewProduct)}>
            <ViewProduct />
          </Route>
          <Route path="/add-company" component={withRouter(AddCompany)}>
            <AddCompany />
          </Route>
          <Route path="/view-company" component={withRouter(ViewCompany)} >
            <ViewCompany />
          </Route>
          <Route path="/add-category" component={withRouter(AddCategory)} >
            <AddCategory />
          </Route>
          <Route path="/view-category" component={withRouter(ViewCategory)} >
            <ViewCategory />
          </Route>
          <Route path="/add-service" component={withRouter(AddService)} >
            <AddService />
          </Route>
          <Route path="/view-service" component={withRouter(ViewService)} >
            <ViewService />
          </Route>
          <Route path="/view-product" component={withRouter(ViewProduct)} >
            <ViewProduct />
          </Route>
          <Route path="/add-member" component={withRouter(AddMember)} >
            <AddMember />
          </Route>
          <Route path="/"  component={withRouter(Login)} >
            <Login />
          </Route>

        </Switch>
      </Router>
      </BrowserRouter>
    </>

  );
}
