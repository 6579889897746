import AddCategory from "../AddCategory";
import AddCompany from "../AddCompany";
import AddMember from "../AddMember";
import AddProduct from "../AddProduct";
import AddService from "../AddService";
import AddStock from "../AddStock";
import Dashboard from "../Dashboard";
import ViewCategory from "../ViewCategory";
import ViewCompany from "../ViewCompany";
import ViewProduct from "../ViewProduct";
import ViewService from "../ViewService";
import ViewStock from "../ViewStock";
import AddProductSale from "../AddProductSale";
import ProductInvoice from "../Invoice";
import ViewProductSale from "../ViewProductSale";
import ViewPayment from "../ViewPayment";
import Drawer from "../Drawer";
import AddDuePayment from "../AddDuePayment";
import ViewMember from "../ViewMember";
import AddServiceSale from "../AddServiceSale";
import ViewAllSale from "../ViewAllSale";
import AddExpense from "../AddExpense";
import ViewExpense from "../ViewExpense";
import MonthlyReport from "../MonthlyReport";
import SampleReport from "../SampleReport";
import Login from "../auth/Login";
import BarChart from "../BarChart";
import ViewProductInvoice from "../ViewProductInvoice";
import SoldProducts from "../SoldProducts";
import AdminProfile from "../AdminProfile";
import SoldServices from "../SoldServices";
import History from "../History";
import AddDailyHistory from "../AddDailyHistory";


const routes = [
    {path: '/admin/dashboard', exact: 'true', name: 'Dashboard', component: Dashboard},
    {path: '/admin/add-company', exact: 'true', name: 'Company', component: AddCompany},
    {path: '/admin/view-company', exact: 'true', name: 'Company', component: ViewCompany},
    {path: '/admin/add-category', exact: 'true', name: 'Category', component: AddCategory},
    {path: '/admin/view-category', exact: 'true', name: 'Category', component: ViewCategory},
    // {path: '/admin/add-product', exact: 'true', name: 'Product', component: AddProduct},
    {path: '/admin/view-product', exact: 'true', name: 'Product', component: ViewProduct},
    // {path: '/admin/add-service', exact: 'true', name: 'Service', component: AddService},
    {path: '/admin/view-service', exact: 'true', name: 'Service', component: ViewService},
    {path: '/admin/add-member', exact: 'true', name: 'Member', component: AddMember},
    {path: '/admin/view-member', exact: 'true', name: 'Member', component: ViewMember},
    {path: '/admin/add-stock', exact: 'true', name: 'Stock', component: AddStock},
    {path: '/admin/view-stock', exact: 'true', name: 'Stock', component: ViewStock},
    {path: '/admin/add-product-sale', exact: 'true', name: 'Sale', component: AddProductSale},
    {path: '/admin/add-service-sale', exact: 'true', name: 'Sale', component: AddServiceSale},
    {path: '/admin/view-product-sale', exact: 'true', name: 'Sale', component: ViewProductSale},
    {path: '/admin/view-payment', exact: 'true', name: 'Payment', component: ViewPayment},
    {path: '/admin/view-all-sale', exact: 'true', name: 'Sale', component: ViewAllSale},
    {path: '/admin/product-invoice', exact: 'true', name: 'Invoice', component: ViewProductInvoice},
    {path: '/admin/drawer', exact: 'true', name: 'Drawer', component: Drawer},
    {path: '/admin/add-due-payment', exact: 'true', name: 'Payment', component: AddDuePayment},
    {path: '/admin/add-expense', exact: 'true', name: 'Expenditure', component: AddExpense},
    {path: '/admin/view-expense', exact: 'true', name: 'Expenditure', component: ViewExpense},
    {path: '/admin/monthly-report', exact: 'true', name: 'Report', component: MonthlyReport},
    {path: '/admin/sample-report', exact: 'true', name: 'Report', component: SampleReport},
    {path: '/admin/sold-products', exact: 'true', name: 'Products', component: SoldProducts},
    {path: '/admin/sold-services', exact: 'true', name: 'Products', component: SoldServices},
    {path: '/admin/profile', exact: 'true', name: 'Bill', component: AdminProfile},
    {path: '/', exact: 'true', name: 'Bill', component: Login},
    {path: '/chart', exact: 'true', name: 'Bill', component: BarChart},
    {path: '/admin/history', exact: 'true', name: 'Bill', component: History},
    {path: '/admin/add-daily-history', exact: 'true', name: 'Expenditure', component: AddDailyHistory},

]

export default routes;