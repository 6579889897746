import React, { Component } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { useState, useEffect } from "react";
import Header from "./Header";

export default function ViewCashBalance() {
  const [data, setData] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    fetch(
      "https://newpos.madadsdigital.com/backend/public/api/view-cash-balance"
    )
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp);
        return resp;
      })
      .then((resp) => setData(resp));
  }, []);

  return (
    <div>
      <div className="col-md-2 col-sm-6 col-12">
        <div className="info-box">
          <span className="info-box-icon bg-success">
            <i className="fas fa-tags" />
          </span>
          <div className="info-box-content">
            <span className="info-box-text">Cash Balance</span>
            <span className="info-box-number">{data}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
