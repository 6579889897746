import React, { Component } from 'react'
import axios from 'axios';

export default class UpdateStock extends Component {
    state = {
        product_code: '',
        quantity:''
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    saveStock = async (e) => {
        e.preventDefault();
        const res = await axios.patch('https://newpos.madadsdigital.com/backend/public/api/update-stock', this.state);
        if (res.data.status === "900") {

            this.setState({
                product_code: '',
                quantity: '',

            })
            alert("Successfully Added")

        }
        else {

            this.setState({
                product_code: '',
                quantity: '',

            })
            alert("Wrong code or add to stock first!")

        }
    }
    render() {
        return (
            <div>
                <div className="form-validation">
                    <form className="form-valide" onSubmit={this.saveStock}>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="form-group row">
                                    <label className="col-lg-4 col-form-label" htmlFor="product_code">Product Code <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                        <input type="text" className="form-control" id="product_code" name="product_code" onChange={this.handleInput} value={this.state.product_code} placeholder="Enter product code" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-4 col-form-label" htmlFor="product_code"> Quantity <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                        <input type="number" className="form-control" id="quantity" name="quantity" onChange={this.handleInput} value={this.state.quantity} placeholder="Enter quantity" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-8 ml-auto">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
