import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import Header from './Header';
import { Redirect } from 'react-router';

export default function MonthlyReport() {

  const [data, setData] = useState([]);


  const columns_sales = [
    {
      title: 'Year', field: 'year', width: '10%',
    },
    {
      title: 'Month', field: 'month',
    },
    {
      title: 'Sale', field: 'paid', type: 'currency', currencySetting:{ locale: 'bdt',currencyCode:'bdt', minimumFractionDigits:0, maximumFractionDigits:0}
    },
    {
      title: 'Due', field: 'due', type: 'currency', currencySetting:{ locale: 'bdt',currencyCode:'bdt', minimumFractionDigits:0, maximumFractionDigits:0}
    },
    {
      title: 'Expense', field: 'expense', type: 'currency', currencySetting:{ locale: 'bdt',currencyCode:'bdt', minimumFractionDigits:0, maximumFractionDigits:0}
    },
    {
      title: 'Profit/Loss', field: 'profit', type: 'currency', currencySetting:{ locale: 'bdt',currencyCode:'bdt', minimumFractionDigits:0, maximumFractionDigits:0}
    },

  ]


  const [iserror, setIserror] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  useEffect(() => {
    fetch('https://newpos.madadsdigital.com/backend/public/api/view-monthly-report')
      .then(resp => resp.json())
      .then(resp => { console.log(resp); return resp; })
      .then(resp => setData(resp))

  }, [])


  const api = axios.create({
    baseURL: `https://newpos.madadsdigital.com/backend/public/`
  })

  const user = JSON.parse(localStorage.getItem('userData'));

  const { id: id, name: name, email: email, password: password, account_type: account_type } = user;
  if (account_type === 'seller') {
    return <Redirect to="/admin/add-product-sale" />;
  }

  return (
    <div>
      <section className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Monthly Report</h3>
                </div>
              </div>
            </div>
            <div className="col-12">
              <MaterialTable
                title="Monthly Report"
                data={data}
                columns={columns_sales}
                options={{
                  exportButton: true,
                  importButton: true,
                  exportAllData: true,
                  pageSizeOptions: [10, 15],
                  pageSize: 12,
                  addRowPosition: 'first',
                  actionsColumnIndex: -1,
                  filtering: false,
                  headerStyle: {
                    position: "sticky",
                    top: "0"
                  },
                  maxBodyHeight: "800px",
                  grouping:true,
                }}
              />
              {/* /.card-body */}
              {/* /.card */}
            </div>
            {/* <div className="col-6">
              <MaterialTable
                title="Expenditure"
                data={data[1]}
                columns={columns_expense}
                options={{
                  exportButton: true,
                  importButton: true,
                  exportAllData: true,
                  pageSizeOptions: [12, 15],
                  pageSize: 12,
                  addRowPosition: 'first',
                  actionsColumnIndex: -1,
                  filtering: true,
                  headerStyle: {
                    position: "sticky",
                    top: "0"
                  },
                  maxBodyHeight: "800px"
                }}
              />
            </div> */}
            {/* <div className="col-3">
            <MaterialTable
                    title="Profit/Loss"
                    data={data[2]}
                    columns={columns_profit_loss}
                    options={{
                      exportButton: true,
                      importButton: true,
                      exportAllData: true,
                      pageSizeOptions: [10, 15],
                      pageSize: 1,
                      addRowPosition: 'first',
                      actionsColumnIndex: -1,
                      filtering: true,
                      headerStyle: {
                        position: "sticky",
                        top: "0"
                      },
                      maxBodyHeight: "800px"
                    }}
                  />
                  </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  )
}

