import React, { Component } from 'react'
import axios from 'axios'


export default class ProductInvoiceTable extends Component {
  constructor(props){
    super(props)
    this.state = {
      sale: [],
      loading:true
    }
  }
  async getUsersData(){
    const res = await axios.get('https://newpos.madadsdigital.com/backend/public/api/view-latest-sale')
    console.log(res.data)
    this.setState({loading:false, sale: res.data})
  }
  componentDidMount(){
    this.getUsersData()
  }
  render() {

    var sale_table = "";

    sale_table = this.state.sale.map( (item) => {
        return (
            <tr>
                <td>{item.product_name}</td>
                <td>{item.unit_price}</td>
                <td>{item.quantity}</td>
                <td>{item.unit_price}</td>
            </tr>

        );

        
    })

    return (
        <div>
            <table style={{fontSize:'14px', width:'280px', paddingTop:'5px', paddingBottom:'10px'}}>
                <thead>
                    <tr>
                        <th style={{width:'140px'}}>
                            Item
                        </th>
                        <th>
                            Price
                        </th>
                        <th>
                            Qt
                        </th>
                        <th>
                            Sub
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sale_table}
                </tbody>
            </table>
        </div>

    )
  }
}