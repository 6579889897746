import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import Header from './Header';
import AddMember from './AddMember';

export default function ViewMember() {

    const [data, setData] = useState([]);

    const columns = [
        {
            title: 'ID', field: 'id', width: '10%'

        },
        {
            title: 'Name', field: 'member_name',
        },
        {
            title: 'Phone Number', field: 'phone_number',
        },
        {
            title: 'Address', field: 'address',
        },
        {
            title: 'Email', field: 'email', type: 'email'
        },
        {
            title: 'Date of Birth', field: 'date_of_birth',
        },
        {
            title: 'Profession', field: 'profession',
        },
        {
            title: 'Note', field: 'note',
        },
        {
            title: 'Points', field: 'points',
        },
    ]

    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-member')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData(resp))

    }, [])

    const api = axios.create({
        baseURL: `https://newpos.madadsdigital.com/backend/public/`
    })

    const handleRowUpdate = (newData, oldData, resolve) => {
        //validation
        let errorList = []

        if (errorList.length < 1) {
            api.patch("api/edit-member/" + newData.id, newData)
                .then(res => {
                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);
                    resolve()
                    setIserror(false)
                    setErrorMessages([])
                    alert("Successfully Updated")
                })
                .catch(error => {
                    setErrorMessages(["Update failed! Server error"])
                    setIserror(true)
                    resolve()

                })
        } else {
            setErrorMessages(errorList)
            setIserror(true)
            resolve()

        }

    }


    const handleRowDelete = (oldData, resolve) => {
        api.delete("api/delete-member/" + oldData.id)
            .then(res => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                resolve()
            })
            .catch(error => {
                setErrorMessages(["Delete failed! Server error"])
                setIserror(true)
                resolve()
            })
    }


    return (
        <div>

            <MaterialTable
                title="Member List"
                data={data}
                columns={columns}
                options={{
                    exportButton: true,
                    importButton: true,
                    exportAllData: true,
                    pageSizeOptions: [10, 15],
                    pageSize: 15,
                    addRowPosition: 'first',
                    actionsColumnIndex: -1,
                    exportFileName: 'FEmmE Members List',
                    columnsButton: true,
                    grouping:true,
                }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            handleRowUpdate(newData, oldData, resolve);

                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            handleRowDelete(oldData, resolve)
                        }),
                }}
            />
        </div>
    )
}

