import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import Header from './Header';
import AddMember from './AddMember';
import moment from 'moment';

export default function ViewHistory() {

    const [data, setData] = useState([]);

    const columns = [
        {
            title: 'Year', field: 'year',
        },
        {
            title: 'Month', field: 'month',
        },
        {
            title: 'Date', field: 'date', type: 'date',
            render: rowData => <p>{moment(rowData.date).format('DD/MM/YYYY')}</p>
            
        },
        {
            title: 'Scr', field: 'scr',
        },
        {
            title: 'Ccr', field: 'ccr',
        },
        {
            title: 'Total Sale', field: 'total_sale',
        },
        {
            title: 'Petty Expense', field: 'petty_expense',
        },
        {
            title: 'Bank Expense', field: 'bank_expense',
        },
        {
            title: 'Total Expense', field: 'total_expense',
        },
        {
            title: 'To Bank', field: 'to_bank',
        },
        {
            title: 'Cash Balance', field: 'balance',
        },
    ]

    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-statement')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData(resp))

    }, [])

    const api = axios.create({
        baseURL: `https://newpos.madadsdigital.com/backend/public/`
    })

    const handleRowUpdate = (newData, oldData, resolve) => {
        //validation
        let errorList = []

        if (errorList.length < 1) {
            api.patch("api/edit-member/" + newData.id, newData)
                .then(res => {
                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);
                    resolve()
                    setIserror(false)
                    setErrorMessages([])
                    alert("Successfully Updated")
                })
                .catch(error => {
                    setErrorMessages(["Update failed! Server error"])
                    setIserror(true)
                    resolve()

                })
        } else {
            setErrorMessages(errorList)
            setIserror(true)
            resolve()

        }

    }


    const handleRowDelete = (oldData, resolve) => {
        api.delete("api/delete-member/" + oldData.id)
            .then(res => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                resolve()
            })
            .catch(error => {
                setErrorMessages(["Delete failed! Server error"])
                setIserror(true)
                resolve()
            })
    }


    return (
        <div>

            <MaterialTable
                title="Particulars"
                data={data}
                columns={columns}
                options={{
                    exportButton: true,
                    importButton: true,
                    exportAllData: true,
                    pageSizeOptions: [7, 10],
                    pageSize: 7,
                    addRowPosition: 'first',
                    actionsColumnIndex: -1,
                    exportFileName: 'FEmmE Statement',
                    columnsButton: true,
                    grouping:true,
                    headerStyle: {
                        position: "sticky",
                        top: "0"
                      },
                      maxBodyHeight: "850px",
                      grouping:true,
                }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            handleRowUpdate(newData, oldData, resolve);

                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            handleRowDelete(oldData, resolve)
                        }),
                }}
            />
        </div>
    )
}

