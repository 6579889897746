import axios from 'axios';
import React, { Component } from 'react'
import Select from 'react-select'
export default class AddExpense extends Component {
    state = {
        particulars: '',
        expenditure_type: '',
        reference: 'none',
        amount: '',
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    saveExpense = async (e) => {
        e.preventDefault();
        const res = await axios.post('https://newpos.madadsdigital.com/backend/public/api/add-expense', this.state);
        if (res.data.status = "900") {
            this.setState({
                particulars: '',
                expenditure_type: '',
                reference: 'none',
                date: '',
            })
            alert("Successfully Added")

        }
    }


    handleChange(e) {
        this.setState({ particulars: e.value })
    }

    componentDidMount() {
        this.getOptions()
    }

    async getOptions() {

        const options = [

            {label: 'FEmmE Cosmetics bill (1)', value: 'FEmmE Cosmetics bill (1)' },
            {label: 'Food Staff (2)', value: 'Food Staff (2)' },
            {label: 'Cosmetics bill (3)', value: 'Cosmetics bill (3)' },
            {label: 'Row Material (4)', value: 'Row Material (4)' },
            {label: 'Maintenance (5)', value: 'Maintenance (5)' },
            {label: 'Hospitality (6)', value: 'Hospitality (6)' },
            {label: 'Conveyance (7)', value: 'Conveyance (7)' },
            {label: 'Monthly Salary Advance (8)', value: 'Monthly Salary Advance (8)' },
            {label: 'Accommodation (9)', value: 'Accommodation (9)' },
            {label: 'Electricity recharge (10)', value: 'Electricity recharge (10)' },
            {label: 'Salary (11)', value: 'Salary (11)' },
            {label: 'VAT/Tax (12)', value: 'VAT/Tax (12)' },
            {label: 'Vat Tips (13)', value: 'Vat Tips (13)' },
            {label: 'Internet Bill (14)', value: 'Internet Bill (14)' },
            {label: 'Rent (15)', value: 'Rent (15)' },
            {label: 'Phone bill (16)', value: 'Phone bill (16)' },
            {label: 'Donation (17)', value: 'Donation (17)' },
            {label: 'Stationary (18)', value: 'Stationary (18)' },
            {label: 'Photocopy (19)', value: 'Photocopy (19)' },
            {label: 'Advance Salary (20)', value: 'Advance Salary (20)' },
            {label: 'Branding & Marketing (21)', value: 'Branding & Marketing (21)' },
            {label: 'Others (22)', value: 'Others (22)' },
            {label: 'Parcel Cosmetics (23)', value: 'Parcel Cosmetics (23)' },
            {label: 'Electronic & Electric Device (24)', value: 'Electronic & Electric Device (24)' },
            {label: 'License Fee (25)', value: 'License Fee (25)' },
            {label: 'Fixed Asset (26)', value: 'Fixed Asset (26)' },
            {label: 'Unofficial (30)', value: 'Unofficial (30)' }
        ]

        

        this.setState({ selectOptions: options })

    }

    render() {
        return (
            <div>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12" float="auto">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Add Expense</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-validation">
                                            <form className="form-valide" onSubmit={this.saveExpense}>
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_name">Date
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="date" className="form-control" id="date" name="date" onChange={this.handleInput} value={this.state.date} placeholder="Select date" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_name">Select Category
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <Select options={this.state.selectOptions} name="particulars" id="particulars" onChange={this.handleChange.bind(this)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="expenditure_type">Expenditure Type
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <select className="form-control select2" id="expenditure_type" name="expenditure_type" onChange={this.handleInput} value={this.state.expenditure_type}>
                                                                    <option value>Please select</option>
                                                                    <option value="Petty">Petty</option>
                                                                    <option value="Bank">Bank</option>
                                                                    <option value="Unofficial">Unofficial</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="reference">Reference
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="reference" name="reference" onChange={this.handleInput} value={this.state.reference} placeholder="Enter reference" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="email">Amount <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control" id="amount" name="amount" onChange={this.handleInput} value={this.state.amount} placeholder="Enter amount" />
                                                            </div>
                                                        </div>
                                                        {/* <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="email">Paid <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control" id="paid" name="paid" onChange={this.handleInput} value={this.state.paid} placeholder="Enter paid amount" />
                                                            </div>
                                                        </div> */}
                                                        <div className="form-group row">
                                                            <div className="col-lg-8 ml-auto">
                                                                <button type="submit" className="btn btn-primary">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
