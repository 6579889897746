import axios from 'axios';
import React, { Component } from 'react'
import ProductList from './ProductList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { data } from 'jquery';
import Select from 'react-select';
import ProductInvoice from './Invoice';
import Invoice from './Invoice';
import SoldProducts from './SoldProducts';
import ProductCart from './ProductCart';

export default class AddProductSale extends Component {



    state = {
        invoice_number: Math.floor(Math.random() * (1000000000 - 5 + 1)) + 5,
        customer_name: '',
        customer_number: '',
        product_code: '',
        quantity: '',
        product_name: '',
        payment_type: '',
        paid: '',
        received:'',
        discount: '0',
        sale_type: 'product',
        quantity: '1',
        company_name:'',
        retail_price: ''
    }

    constructor(props) {
        super(props)
        this.state = {
            selectOptions: [],
            invoice_number: Math.floor(Math.random() * (1000000000 - 5 + 1)) + 5,
            product_code: '',
            product_name: '',
            quantity: '1',
            discount: '0',
            company_name:'',
            retail_price: ''

        }
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    productHandleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        this.getOptions();
    }

    async getOptions() {
        const res = await axios.get('https://newpos.madadsdigital.com/backend/public/api/view-available-product')
        const data = res.data
        const filtered_data = data.filter(data => {
            return data.product_type === this.state.product_type;
        })
        const options = filtered_data.map(d => ({
            "product_code": d.product_code,
            "label": d.product_name + " " + "("+d.company_name+")" + d.product_size + " " + "৳ "+d.retail_price+ " "   + "(" + d.balance_stock + " units left)" + " " + d.product_type

        }))

        this.setState({ selectOptions: options })

    }


    handleChange(e) {
        this.setState({ product_code: e.product_code, product_name: e.label })
    }


    componentDidMount() {
        this.getOptions()
    }

    saveItem = async (e) => {
        e.preventDefault();
        console.log(this.state)
        const res = await axios.post('https://newpos.madadsdigital.com/backend/public/api/add-product-sale', this.state);
        if (res.data.status === "1900") {

            alert('clear the invoice first');
            console.log(res.status)
            console.log(res.data.message)
            this.setState({
                product_name: '',
                product_code: '',
                quantity: '1',
                discount: '0',
            })

        }
        else if (res.data.status === "1600") {
            toast(this.state.product_name)
            this.setState({
                product_name: '',
                product_code: '',
                quantity: '1',
                discount: '0',

            })
        }
        else {
            console.log(res.status)
            console.log(res.data.message)
            toast("Not enough stock!")
            this.setState({
                product_name: '',
                product_code: '',
                quantity: '1',
                discount: '0',
            })
        }

    }

    savePayment = async (e) => {
        e.preventDefault();
        console.log(this.state)
        const res = await axios.post('https://newpos.madadsdigital.com/backend/public/api/add-product-cash-in', this.state);
        if (res.data.status = "1200") {
            alert("Payment submitted!")
            this.setState({
                payment_type: '',
                paid: '',
                received:'',

            })

            window.location.reload();

        }
    }

    cancelProductSale = async (e) => {
        e.preventDefault();

        const res = await axios.post('https://newpos.madadsdigital.com/backend/public/api/cancel-product-sale');
        if (res.status = "2500") {
            toast("Sale Canceled")
            window.location.reload();
        }

    }

    clearProductInvoice = async (e) => {
        e.preventDefault();

        const res = await axios.post('https://newpos.madadsdigital.com/backend/public/api/clear-invoice');
        if (res.status = "2500") {
            toast("Invoice cleared")
            window.location.reload();
        }

    }

    generateInvoice = async (e) => {
        e.preventDefault();
        toast("Invoice generated")
        window.location.reload();
        const res = await axios.fetch('https://newpos.madadsdigital.com/backend/public/api/view-latest-sale');
        if (res.data.status = "1800") {
            this.setState({
                invoice_number: '',
                quantity: ''
            })
        }
    }

    print() {
        window.print(document.getElementById('invoice'));
    }

    render() {

        return (
            <div>
                {/* /.payment-modal-content */}
                <div className="modal fade" id="modal-primary">
                    <div className="modal-dialog modal-primary">
                        <div className="modal-content">
                            <form className="form-valide" onSubmit={this.savePayment}>
                                <div className="modal-header">
                                    <h4 className="modal-title">Payment</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <label className="col-lg-4 col-form-label" htmlFor="product_name">Payment Type
                                        <span className="text-danger">*</span>
                                    </label>

                                    <select className="form-control select2" id="payment_type" name="payment_type" onChange={this.handleInput} value={this.state.payment_type} >
                                        <option value>Please select</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Money Transfer">Money Transfer</option>
                                        <option value="Card/Cheque">Card/Cheque</option>
                                    </select>
                                    <br></br>
                                    <label className="col-lg-4 col-form-label" htmlFor="product_name">Total Received
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="received" name="received" onChange={this.handleInput} value={this.state.received} placeholder="Enter received amount" />
                                    <br></br>
                                    <label className="col-lg-4 col-form-label" htmlFor="product_name">Payment
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="paid" name="paid" onChange={this.handleInput} value={this.state.paid} placeholder="Enter paid amount" />

                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Confirm Payment</button>
                                </div>
                            </form>
                        </div>
                        {/* /.modal-content */}
                    </div>
                    {/* /.modal-dialog */}
                </div>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8 no-print" float="auto">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">FEmmE Invoice</h4>
                                    </div>
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={500000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
                                    <div className="card-body" >
                                        <div className="form-validation">
                                            <form className="form-valide" onSubmit={this.saveItem}>
                                                <div className='row'>
                                                    <div className="col-xl-12">
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_name">Customer Number
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" minLength="11" maxLength="11" className="form-control" id="customer_number" name="customer_number" onChange={this.handleInput} value={this.state.customer_number} placeholder="Enter customer number" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_name">Customer Name
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="customer_name" name="customer_name" onChange={this.handleInput} value={this.state.customer_name} placeholder="Enter customer name" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_type">Section
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <select className="form-control select2" id="product_type" name="product_type" onChange={this.productHandleInput} value={this.state.product_type}>
                                                                    <option value>Please select</option>
                                                                    <option value="Face">Face</option>
                                                                    <option value="Hair">Hair</option>
                                                                    <option value="Body">Body</option>
                                                                    <option value="Color Cosmetics">Color Cosmetics</option>
                                                                    <option value="Sanitary">Sanitary</option>
                                                                    <option value="Fragrance">Fragrance</option>
                                                                    <option value="Baby Product">Baby Product</option>
                                                                    <option value="Jwellery">Jwellery</option>
                                                                    <option value="Other Products">Other Products</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_name">Select Item
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6" style={{zIndex:1000}}>
                                                                <Select options={this.state.selectOptions} name="product_name" id="product_name" onChange={this.handleChange.bind(this)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_name">Quantity
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control" id="quantity" name="quantity" onChange={this.handleInput} value={this.state.quantity} placeholder="Enter quantity" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="discount">Discount
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control" id="discount" name="discount" onChange={this.handleInput} value={this.state.discount} placeholder="Enter discount amount" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-lg-8 ml-auto">
                                                                <button type="submit" onClick={this.saveItem} className="btn btn-success"><i className="fas fa-plus" /> Add Item</button>
                                                                {/* <button type="submit" onClick={this.generateInvoice} className="btn btn-success" float="left" style={{ marginLeft: 15 }}><i className="fas fa-file-invoice-dollar" /> Generate Invoice</button> */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <ProductCart />
                                        <div className="col-12">
                                            {/* <form onSubmit={this.cancelProductSale}>
                                                <button type="submit" className="btn btn-danger float-left"><i className="fas fa-close" />
                                                    Cancel Sale
                                                </button>
                                            </form> */}
                                            {/* <form onSubmit={this.clearProductInvoice}>
                                                <button type="submit" className="btn btn-success float-left" style={{ marginLeft: '5px' }}><i className="fas fa-broom" />
                                                    Clear invoice
                                                </button>
                                            </form> */}
                                        </div>
                                    </div>

                                </div>
                                {/* /.invoice */}
                            </div>
                            <div className="col-lg-4" >
                                <div id="invoice">
                                    <div className="row">
                                        <Invoice
                                        />
                                    </div>
                                    {/* /.row */}
                                    {/* /.row */}
                                    {/* this row will not appear when printing */}
                                </div>
                                <div className="col-lg-6 no-print" float="auto" >
                                    <button type="submit" onClick={this.generateInvoice} className="btn btn-success" style={{ marginBottom: '10px', display:'block', width:'100%' }} ><i className="fas fa-file-invoice-dollar" /> Generate Invoice</button>
                                    <button type="button"  data-toggle="modal" data-target="#modal-primary" className="btn btn-warning" style={{ marginBottom: '10px', display:'block', width:'100%' }}><i className="far fa-credit-card" /> Submit
                                        Payment
                                    </button>
                                    <button type="button" className="btn btn-primary " onClick={this.print} style={{ marginBottom: '10px', display:'block', width:'100%' }}>
                                        <i className="fas fa-print" /> Print Invoice
                                    </button>
                                    <form onSubmit={this.clearProductInvoice}>
                                        <button type="submit" className="btn btn-danger float-left" style={{ marginBottom: '10px', display:'block', width:'100%' }}>
                                            <i className="fas fa-broom" />
                                             Clear invoice
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
