import React, { Component } from 'react'
import { Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';

export default class Header extends Component {


    state = {
        logout: false,
    };

    componentDidMount() {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-daily-sale')
        .then((response) => response.json())
        .then(sale => {
            this.setState({ sale: sale });
        });

        fetch('https://newpos.madadsdigital.com/backend/public/api/view-daily-expense')
        .then((response) => response.json())
        .then(expense => {
            this.setState({ expense: expense });
        });

    }

    onLogoutHandler = () => {
        window.location.reload();
        localStorage.clear();
        this.setState({
            logout: true,
        });
    };
    render() {
        const user = JSON.parse(localStorage.getItem("userData"));
        const { logout } = this.state;
        if (logout) {

            return <Redirect to="/" push={true} />;

        }
        return (
            <div>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                        </li>
                        <NavLink to="/admin/add-product-sale" >
                            <button className='btn btn-warning' style={{ marginRight: '5px' }}><i className="fas fa-plus nav-icon" /> Product Sale</button>
                        </NavLink>
                        <NavLink to="/admin/add-service-sale" >
                            <button className='btn btn-warning' style={{ marginRight: '5px' }} ><i className="fas fa-plus nav-icon" /> Service Sale</button>
                        </NavLink>
                        <NavLink to="/admin/add-expense" >
                            <button className='btn btn-warning' style={{ marginRight: '5px' }} ><i className="fas fa-plus nav-icon" /> Expenditure</button>
                        </NavLink>

                        <li className="nav-item" style={{fontSize:'18px', color:'blue'}}>
                            <a className="nav-link" href="/admin/view-all-sale" role="button" ><i className="fas fa-tags"  /> <strong >Today's Sale: {this.state.sale}  </strong></a>
                        </li>
                        <li className="nav-item" style={{fontSize:'18px'}}>
                            <a className="nav-link" href="/admin/view-expense" role="button"><i className="fas fa-calculator"  /> <strong>Today's Expense: {this.state.expense}  </strong></a>
                        </li>

                    </ul>
                    <ul className="navbar-nav ml-auto">
                        {/* Notifications Dropdown Menu */}

                        {/* <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#">
                                <i className="fas fa-bars" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <span className="dropdown-item dropdown-header">Account</span>
                                <div className="dropdown-divider" />
                                <a href="/admin/profile" className="dropdown-item">
                                    <i className="fas fa-user" /> Profile
                                </a>
                                <div className="dropdown-divider" />
                                <a href="#" className="dropdown-item">
                                    <div onClick={this.onLogoutHandler}>
                                        <i className="fas fa-sign-out-alt" /> Logout
                                    </div>
                                </a>
                            </div>
                        </li> */}

                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#">
                                <i className="far fa-user-circle fa-2x" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <span className="dropdown-item dropdown-header">Account</span>
                                <div className="dropdown-divider" />
                                <a href="/admin/profile" className="dropdown-item">
                                    <i className="fas fa-user" /> Profile
                                </a>
                                <div className="dropdown-divider" />
                                <a href="#" className="dropdown-item">
                                    <div onClick={this.onLogoutHandler}>
                                        <i className="fas fa-sign-out-alt" /> Logout
                                    </div>
                                </a>
                            </div>
                        </li>
                    </ul>
                </nav>

            </div>
        )
    }
}
