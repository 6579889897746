import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import Header from './Header';

export default function ViewExpense() {

  const [data, setData] = useState([]);

  const columns = [

    {
      title: 'Date', field: 'date', type:'date'
    },
    {
      title: 'Category', field: 'particulars',
    },
    {
      title: 'Expenditure Type', field: 'expenditure_type',
    },
    {
      title: 'Reference', field: 'reference',
    },
    {
      title: 'Total Amount', field: 'amount',
    },
  ]

  const [iserror, setIserror] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  useEffect(() => {
    fetch('https://newpos.madadsdigital.com/backend/public/api/view-expense')
      .then(resp => resp.json())
      .then(resp => { console.log(resp); return resp; })
      .then(resp => setData(resp))

  }, [])

  const api = axios.create({
    baseURL: `https://newpos.madadsdigital.com/backend/public/`
  })

  const handleRowUpdate = (newData, oldData, resolve) => {
    //validation
    let errorList = []

    if (errorList.length < 1) {
      api.patch("api/update-expense/" + newData.id, newData)
        .then(res => {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          setData([...dataUpdate]);
          resolve()
          setIserror(false)
          setErrorMessages([])
          alert("Successfully Updated")
        })
        .catch(error => {
          setErrorMessages(["Update failed! Server error"])
          setIserror(true)
          resolve()

        })
    } else {
      setErrorMessages(errorList)
      setIserror(true)
      resolve()

    }

  }


  const handleRowDelete = (oldData, resolve) => {
    api.delete("api/delete-expense/" + oldData.id)
      .then(res => {
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        resolve()
      })
      .catch(error => {
        setErrorMessages(["Delete failed! Server error"])
        setIserror(true)
        resolve()
      })
  }


  return (
    <div>

      <section className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Expenditure List</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <MaterialTable
                    title="Expenditure List"
                    data={data}
                    columns={columns}
                    options={{
                      exportButton: true,
                      importButton: true,
                      exportAllData: true,
                      pageSizeOptions: [10, 15],
                      pageSize: 15,
                      addRowPosition: 'first',
                      actionsColumnIndex: -1,
                      exportFileName: 'Expense List',
                      columnsButton: true,
                      filtering: true,
                      headerStyle: {
                        position: "sticky",
                        top: "0"
                      },
                      maxBodyHeight: "800px",
                      grouping: true,
                    }}
                  editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            handleRowUpdate(newData, oldData, resolve);

                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          handleRowDelete(oldData, resolve)
                        }),
                    }}
                  />
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  )
}

