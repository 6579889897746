import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import Header from './Header';

export default function ShortItems() {

    const [data, setData] = useState([]);

    const columns = [
        {
            title: 'ID', field: 'id', width:'10%'

        },
        {
            title: 'Product Name', field: 'product_name',
        },
        {
            title: 'Balance Stock', field: 'balance_stock',
        },
        {
            title: 'Size', field: 'product_size',
        },
        {
            title: 'Product Code', field: 'product_code',
        },

    ]

    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-short-items')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData(resp))

    }, [])

    const api = axios.create({
        baseURL: `https://newpos.madadsdigital.com/backend/public/`
      })

    const handleRowUpdate = (newData, oldData, resolve) => {
        //validation
        let errorList = []

        if(errorList.length < 1){
          api.patch("api/update-product/"+oldData.id, newData)
          .then(res => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            setData([...dataUpdate]);
            resolve()
            setIserror(false)
            setErrorMessages([])
            alert("Successfully Updated")
          })
          .catch(error => {
            setErrorMessages(["Update failed! Server error"])
            setIserror(true)
            resolve()
             
          })
        }else{
          setErrorMessages(errorList)
          setIserror(true)
          resolve()
     
        }
         
      }
     
      const handleRowAdd = (newData, resolve) => {
        //validation
        let errorList = []
        if(newData.company_name === undefined){
          errorList.push("Please enter first name")
        }
     
        if(errorList.length < 1){ //no error
          api.post("api/add-product", newData)
          .then(res => {
            let dataToAdd = [...data];
            dataToAdd.push(newData);
            setData(dataToAdd);
            resolve()
            setErrorMessages([])
            setIserror(false)
          })
          .catch(error => {
            setErrorMessages(["Cannot add data. Server error!"])
            setIserror(true)
            resolve()
          })
        }else{
          setErrorMessages(errorList)
          setIserror(true)
          resolve()
        }
      }

      const handleRowDelete = (oldData, resolve) => {
        api.delete("api/delete-product/"+oldData.id)
          .then(res => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            setData([...dataDelete]);
            resolve()
          })
          .catch(error => {
            setErrorMessages(["Delete failed! Server error"])
            setIserror(true)
            resolve()
          })
      }


    return (
        <div>
        
                        <div className="col-lg-12">
                                {/* /.card-header */}
                                
                                    <MaterialTable
                                        title="Short Items"
                                        data={data}
                                        columns={columns}
                                        options={{
                                            exportButton: false,
                                            exportAllData: false,
                                            pageSizeOptions: [10, 15],
                                            pageSize: 8,
                                            addRowPosition: 'first',
                                            actionsColumnIndex: -1,
                                            columnsButton:false,
                                            exportAllData:true,
                                            exportButton:true,
                                            grouping:true,
                                            
                                        }}
                                    />
                              
                                {/* /.card-body */}
                            
                            {/* /.card */}
                        </div>
                        {/* /.col */}

        </div>
    )
}

