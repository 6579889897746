import axios from 'axios';
import React, { Component } from 'react'
import { MenuItem, Select } from '@material-ui/core';
import ViewStock from './ViewStock';
import ProductList from './ProductList';
import ViewHistory from './ViewHistory';




export default class History extends Component {




    state = {
        start_date: '',
        end_date: ''
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    saveStock = async (e) => {
        e.preventDefault();
        const res = await axios.post('https://newpos.madadsdigital.com/backend/public/api/view-history', this.state);
        if (res.data.status === "900") {
            this.setState({
                scr: res.data.scr,
                ccr: res.data.ccr,
                sale: res.data.sale,
                expense: res.data.expense,
                balance: res.data.balance
            })
        }
        else {
            this.setState({
                start_date: '',
                end_date: '',
            })
            alert("Something is wrong!")
        }
    }
    render() {
        return (
            <div>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12" float="auto">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">History</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-validation">
                                            <form className="form-valide" onSubmit={this.saveStock}>
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="form-group row">
                                                            <label className="col-lg-2 col-form-label" htmlFor="start_date">Start Date <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-2">
                                                                <input type="date" className="form-control" id="start_date" name="start_date" onChange={this.handleInput} value={this.state.start_date} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-2 col-form-label" htmlFor="end_date"> End Date <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-2">
                                                                <input type="date" className="form-control" id="end_date" name="end_date" onChange={this.handleInput} value={this.state.end_date} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-lg-4">
                                                                <button type="submit" className="btn btn-primary" style={{ float: 'right' }} >Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-2 col-sm-6 col-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-success"><i className="fas fa-tags" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Scr</span>
                                        <span className="info-box-number">{this.state.scr}</span>
                                    </div>
                                    {/* /.info-box-content */}
                                </div>
                                {/* /.info-box */}
                            </div>
                            <div className="col-md-2 col-sm-6 col-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-success"><i className="fas fa-tags" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Ccr</span>
                                        <span className="info-box-number">{this.state.ccr}</span>
                                    </div>
                                    {/* /.info-box-content */}
                                </div>
                                {/* /.info-box */}
                            </div>
                            <div className="col-md-2 col-sm-6 col-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-success"><i className="fas fa-tags" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Sale</span>
                                        <span className="info-box-number">{this.state.sale}</span>
                                    </div>
                                    {/* /.info-box-content */}
                                </div>
                                {/* /.info-box */}
                            </div>
                            {/* /.col */}
                            <div className="col-md-2 col-sm-6 col-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-success"><i className="fas fa-dollar" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Expense</span>
                                        <span className="info-box-number">{this.state.expense}</span>
                                    </div>
                                    {/* /.info-box-content */}
                                </div>
                                {/* /.info-box */}
                            </div>
                            <div className="col-md-2 col-sm-6 col-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-success"><i className="fas fa-dollar" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Balance</span>
                                        <span className="info-box-number">{this.state.balance}</span>
                                    </div>
                                    {/* /.info-box-content */}
                                </div>
                                {/* /.info-box */}
                            </div>
                        </div>
                        <ViewHistory/>
                    </div>
                </div>
            </div>
        )
    }
}
