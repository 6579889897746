import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect, useMemo } from 'react';
import Header from './Header';
import ProductInvoiceTable from './ProductInvoiceTable';


export default function DueInvoice() {


    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);
    const [data5, setData5] = useState([]);
    const [data6, setData6] = useState([]);


    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-due-invoice')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData(resp))

    }, [])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-due-total-price')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData2(resp))

    }, [])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-due-total-paid')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData3(resp))

    }, [])

    // useEffect(() => {
    //     fetch('https://newpos.madadsdigital.com/backend/public/api/view-subtotal')
    //         .then(resp => resp.json())
    //         .then(resp => { console.log(resp); return resp; })
    //         .then(resp => setData5(resp))

    // }, [])

    // useEffect(() => {
    //     fetch('https://newpos.madadsdigital.com/backend/public/api/view-discount')
    //         .then(resp => resp.json())
    //         .then(resp => { console.log(resp); return resp; })
    //         .then(resp => setData6(resp))

    // }, [])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-due-total-due')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData4(resp))

    }, [])


    const api = axios.create({
        baseURL: `https://newpos.madadsdigital.com/backend/public/`
    })

    const handleRowDelete = (oldData, resolve) => {
        api.delete("api/delete-product/" + oldData.id)
            .then(res => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                resolve()
            })
            .catch(error => {
                setErrorMessages(["Delete failed! Server error"])
                setIserror(true)
                resolve()
            })
    }

    let today = new Date();
    let date = today.getDate() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getFullYear();


    const user = JSON.parse(localStorage.getItem('userData'));

    const { id: id, name: name, email: email, password: password, account_type: account_type } = user;


    var hours = today.getHours();
    var minutes = today.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    return (
        <div>
            {/* /.card-header */}
            <div className="invoice p-3 mb-3" id="invoice" style={{ width: '320px', height: 'auto', fontSize: '12px', fontWeight: '400', }}>
                {/* info row */}
                <div className="row invoice-info">
                    <div className="col-12" style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                        <img src="../dist/img/logo.jpg" style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }} />
                    </div>
                    <div className='col-sm-12 '>
                        <address  >
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', fontSize: '16px' }}>
                                08 Noyasarak, Sylhet 3100<br />
                                Phone: 01712115562<br />
                                www.femmesylhet.com
                            </div>
                        </address>
                    </div>
                    {/* /.col */}

                    <div className="col-6 " style={{ fontSize: '14px' }}>
                        <b>Date: {date}
                        </b>
                    </div>
                    <div className="col-6 " style={{ fontSize: '14px', textAlign: 'right' }}>
                        <b>Time: {strTime}
                        </b>
                    </div>
                    <div className='col-sm-12 ' style={{ float: 'left', fontSize: '14px' }}>
                        <b>Due Invoice: {data}
                        </b>
                    </div>
                </div>
                {/* /.row */}
                {/* Table row */}

                {/* <MaterialTable
                    data={data}
                    columns={columns}
                    options={{
                        pageSizeOptions: [5, 10],
                        pageSize: 20,
                        addRowPosition: 'first',
                        actionsColumnIndex: 0,
                        search: false,
                        showTitle: false,
                        paging: false,
                        toolbar: false,
                        tableLayout: 'auto',

                    }}

                // editable={{
                //     onRowDelete: (oldData) =>
                //         new Promise((resolve) => {
                //             handleRowDelete(oldData, resolve)
                //         }),
                // }}
                /> */}
                {/* /.row */}
                <div className="row">
                    {/* accepted payments column */}
                    {/* /.col */}
                    <div className="col-12" style={{ fontSize: '18px' }}>
                        <div className="table-responsive" style={{ textAlign: 'right' }}>
                            <table style={{ float: 'right', marginTop: '20px', marginRight: '50px' }} >
                                <tbody>
                                    <tr>
                                        <th style={{ width: '80%', textAlign: 'right' }}><strong>Total Amount: </strong></th>
                                        <td>
                                            {data2}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Total Paid:</th>
                                        <td>{data3}</td>
                                    </tr>
                                    <tr>
                                        <th>Due:</th>
                                        <td>{data4}</td>
                                    </tr>
                                </tbody></table>
                        </div>
                    </div>
                    <div className='col-sm-12' style={{ fontSize: '14px' }}>
                        <strong>
                            <br></br>
                            <br></br>
                            Signature: {name}
                            <br></br>
                            <br></br>
                        </strong>
                    </div>
                    {/* <div className='col-sm-12' style={{ marginBottom: '10px', fontSize: '14px' }}>
                        <strong>
                            Service Provider:
                            <br></br>
                            <br></br>
                        </strong>
                    </div> */}

                    <div className='col-sm-12' style={{ marginTop: '25px', }}>
                        <p style={{ textAlign: 'center', fontSize: '16px' }}>
                            <i className="fas fa-code" /> Developed By: Mad Tech Digital
                        </p>
                    </div>
                    {/* /.col */}
                </div>
                {/* /.row */}
                {/* this row will not appear when printing */}
            </div>
        </div>
    )
}

