import axios from 'axios';
import React, { Component } from 'react'
import { MenuItem, Select } from '@material-ui/core';
import DueList from './DueList';
import DueInvoice from './DueInvoice';
import { ToastContainer, toast } from 'react-toastify';




export default class AddDuePayment extends Component {
    state = {
        invoice_number: '',
        payment_method: '',
        amount: '',
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    clearDueInvoice = async (e) => {
        e.preventDefault();

        const res = await axios.post('https://newpos.madadsdigital.com/backend/public/api/clear-invoice');
        if (res.status = "2500") {
            toast("Invoice cleared")
            window.location.reload();
        }
    }
    savePayment = async (e) => {
        e.preventDefault();
        const res = await axios.post('https://newpos.madadsdigital.com/backend/public/api/add-due-payment', this.state);
        if (res.data.status = "900") {
            this.setState({
                invoice_number: '',
                payment_method: '',
                amount: '',

            })
            alert("Successfully Added")
        }
        window.location.reload();
    }

    // generateInvoice = async (e) => {
    //     e.preventDefault();
    //     toast("Invoice generated")
    //     window.location.reload();
    //     const res = await axios.fetch('https://newpos.madadsdigital.com/backend/public/api/show-due-invoice');
    //     if (res.data.status = "1800") {
    //         this.setState({
    //             invoice_number: '',
    //             quantity: ''
    //         })
    //     }
    // }

    print() {
        window.print(document.getElementById('invoice'));
    }

    render() {
        return (
            <div>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8 no-print" float="auto">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Add Due Payment</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-validation">
                                            <form className="form-valide" onSubmit={this.savePayment}>
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="invoice_number">Invoice Number
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="invoice_number" name="invoice_number" onChange={this.handleInput} value={this.state.invoice_number} placeholder="Enter invoice number" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">

                                                            <label className="col-lg-4 col-form-label" htmlFor="product_name">Payment Method
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <select className="form-control select2" id="payment_method" name="payment_method" onChange={this.handleInput} value={this.state.payment_method}>
                                                                    <option value>Please select</option>
                                                                    <option value="Cash">Cash</option>
                                                                    <option value="Money Transfer">Money Transfer</option>
                                                                    <option value="Card/Cheque">Card/Cheque</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_size">Amount
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="amount" name="amount" onChange={this.handleInput} value={this.state.amount} placeholder="Enter amount" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-lg-8 ml-auto">
                                                                <button type="submit" className="btn btn-primary">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <DueList />
                            </div>
                            <ToastContainer
                                        position="top-right"
                                        autoClose={500000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
                            <div className="col-lg-4" float="auto">
                                <DueInvoice/>
                                <div className="col-lg-6 no-print" float="auto" >
                                {/* <button type="submit" onClick={this.generateInvoice} className="btn btn-success" style={{ marginBottom: '10px', display:'block', width:'100%' }} ><i className="fas fa-file-invoice-dollar" /> Generate Invoice</button> */}
                                    <button type="button" className="btn btn-primary " onClick={this.print} style={{ marginBottom: '10px', display:'block', width:'100%' }}>
                                        <i className="fas fa-print" /> Print Invoice
                                    </button>
                                    <form onSubmit={this.clearDueInvoice}>
                                        <button type="submit" className="btn btn-danger float-left" style={{ marginBottom: '10px', display:'block', width:'100%' }}>
                                            <i className="fas fa-broom" />
                                             Clear invoice
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
