import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import Header from './Header';



export default function ViewAllSale() {

    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);
    const [data5, setData5] = useState([]);
    const [data6, setData6] = useState([]);



    const columns = [
        {
            title: 'Payment Date', field: 'created_at', type: 'date'
        },
        {
            title: 'Time', field: 'created_at',
        },
        {
            title: 'ID', width: '10%', field: 'id',
        },
        {
            title: 'Invoice Number', field: 'invoice_number',
        },
        {
            title: 'Customer Name', field: 'customer_name',
        },
        {
            title: 'Customer Number', field: 'customer_number',
        },
        {
            title: 'Total Payment', field: 'total_payment',
        },
        {
            title: 'Paid', field: 'paid',
        },
        {
            title: 'Due', field: 'due',
        },
        {
            title: 'Payment Type', field: 'payment_type',
        },
        {
            title: 'Sale Type', field: 'sale_type',
        },

    ]

    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    useEffect(() => {
        fetch('https://newpos.madadsdigital.com/backend/public/api/view-all-cash-in')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData(resp))

        fetch('https://newpos.madadsdigital.com/backend/public/api/view-daily-cash')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData2(resp))

        fetch('https://newpos.madadsdigital.com/backend/public/api/view-daily-bank')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData3(resp))

        fetch('https://newpos.madadsdigital.com/backend/public/api/view-daily-service-sale')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData4(resp))

        fetch('https://newpos.madadsdigital.com/backend/public/api/view-daily-product-sale')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData5(resp))

        fetch('https://newpos.madadsdigital.com/backend/public/api/view-daily-balance')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData6(resp))

    }, [])

    const api = axios.create({
        baseURL: `https://newpos.madadsdigital.com/backend/public/`
    })

    const handleRowUpdate = (newData, oldData, resolve) => {
        //validation
        let errorList = []

        if (errorList.length < 1) {
            api.patch("api/update-payment/" + newData.id, newData)
                .then(res => {
                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);
                    resolve()
                    setIserror(false)
                    setErrorMessages([])
                    alert("Successfully Updated")
                })
                .catch(error => {
                    setErrorMessages(["Update failed! Server error"])
                    setIserror(true)
                    resolve()

                })
        } else {
            setErrorMessages(errorList)
            setIserror(true)
            resolve()

        }

    }

    //   const handleRowAdd = (newData, resolve) => {
    //     //validation
    //     let errorList = []

    //     if(errorList.length < 1){ //no error
    //       api.post("api/add-product", newData)
    //       .then(res => {
    //         let dataToAdd = [...data];
    //         dataToAdd.push(newData);
    //         setData(dataToAdd);
    //         resolve()
    //         setErrorMessages([])
    //         setIserror(false)
    //       })
    //       .catch(error => {
    //         setErrorMessages(["Cannot add data. Server error!"])
    //         setIserror(true)
    //         resolve()
    //       })
    //     }else{
    //       setErrorMessages(errorList)
    //       setIserror(true)
    //       resolve()
    //     }
    //   }

    const handleRowDelete = (oldData, resolve) => {
        api.delete("api/delete-sale/" + oldData.id)
            .then(res => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                resolve()
            })
            .catch(error => {
                setErrorMessages(["Delete failed! Server error"])
                setIserror(true)
                resolve()
            })
    }


    return (
        <div>

            <section className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-2 col-sm-6 col-12">
                                        <div className="info-box">
                                            <span className="info-box-icon bg-success"><i className="fas fa-tags" /></span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">Ccr</span>
                                                <span className="info-box-number">{data5}</span>
                                            </div>
                                            {/* /.info-box-content */}
                                        </div>
                                        {/* /.info-box */}
                                    </div>
                                    {/* /.col */}
                                    <div className="col-md-2 col-sm-6 col-12">
                                        <div className="info-box">
                                            <span className="info-box-icon bg-success"><i className="far fa-hand-scissors" /></span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">Scr</span>
                                                <span className="info-box-number">{data4}</span>
                                            </div>
                                            {/* /.info-box-content */}
                                        </div>
                                        {/* /.info-box */}
                                    </div>
                                    {/* /.col */}
                                    <div className="col-md-2 col-sm-6 col-12">
                                        <div className="info-box">
                                            <span className="info-box-icon bg-success"><i className="fas fa-wallet" /></span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">Balance</span>
                                                <span className="info-box-number">{data6}</span>
                                            </div>
                                            {/* /.info-box-content */}
                                        </div>
                                        {/* /.info-box */}
                                    </div>
                                    {/* /.col */}
                                    <div className="col-md-2 col-sm-6 col-12">
                                        <div className="info-box">
                                            <span className="info-box-icon bg-success"><i className="fas fa-dollar" /></span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">Cash</span>
                                                <span className="info-box-number">{data2}</span>
                                            </div>
                                            {/* /.info-box-content */}
                                        </div>
                                        {/* /.info-box */}
                                    </div>
                                    <div className="col-md-2 col-sm-6 col-12">
                                        <div className="info-box">
                                            <span className="info-box-icon bg-success"><i className="fas fa-money-check-alt" /></span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">Non Cash</span>
                                                <span className="info-box-number">{data3}</span>
                                            </div>
                                            {/* /.info-box-content */}
                                        </div>
                                        {/* /.info-box */}
                                    </div>
                                    {/* /.col */}
                                </div>
                                <div className="card-header">
                                    <h3 className="card-title">View All Sale</h3>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <MaterialTable
                                        title="Sale list"
                                        data={data}
                                        columns={columns}
                                        options={{
                                            exportButton: true,
                                            importButton: true,
                                            exportAllData: true,
                                            pageSizeOptions: [10, 15],
                                            pageSize: 15,
                                            addRowPosition: 'first',
                                            actionsColumnIndex: -1,
                                            filtering: true,
                                            columnsButton: true,
                                            headerStyle: {
                                                position: "sticky",
                                                top: "0"
                                            },
                                            maxBodyHeight: "800px",
                                            grouping: true,


                                        }}
                                        editable={{
                                            // onRowUpdate: (newData, oldData) =>
                                            //   new Promise((resolve) => {
                                            //       handleRowUpdate(newData, oldData, resolve);

                                            //   }),
                                            // onRowAdd: (newData) =>
                                            //   new Promise((resolve) => {
                                            //     handleRowAdd(newData, resolve)
                                            //   }),
                                            onRowDelete: (oldData) =>
                                                new Promise((resolve) => {
                                                    handleRowDelete(oldData, resolve)
                                                }),
                                        }}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
        </div>
    )
}

